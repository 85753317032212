/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  text-overflow: clip !important;
  white-space: normal !important;
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: #00a4ef;
}

.alert-checkbox-group.sc-ion-alert-md,
.alert-radio-group.sc-ion-alert-md {
  padding-bottom: 8px;
}

.alert-wrapper {
  background-color: white !important;
}

.titolo-toolbar {
  margin-left: 10px !important;
  font-weight: bold !important;
}

main {
  background-color: #040f23 !important;
}

ion-toolbar {
  --background: #00afff;
  color: white;
}

ion-datetime {
  color: white;
}

ion-tab-bar {
  --background: white;
}

.testoCustom {
  color: #00afff !important;
}

.button-round,
.button-round-md {
  background: #00afff !important;
  color: #ffffff !important;
}

h2,
h3,
p {
  color: #00afff !important;
}

.dataOmbrelloni {
  color: #ffffff !important;
}

.centraImmagine {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.label,
.select-text,
.datetime-text {
  color: #00afff !important;
}

.button-round-md {
  background: transparent;
  color: #00afff;
}

.action-sheet-md .action-sheet-button {
  color: #00afff !important;
}

picker-opts {
  color: #fff;
}

ion-label {
  margin-left: 16px;
  margin-right: 16px;
}

.txtServizio {
  font-size: 2.8vw;
}

.scroll-content {
  background-color: #040f23;
}

.item {
  background-color: #040f23;
  //padding-left: 16px;
  padding-right: 16px;
}

ion-picker-column {
  color: #00afff;
  background-color: #fff;
}

.ion-icon-toolbar {
  float: right;
  margin-right: 10px;
}

.picker-button.sc-ion-picker-ios,
.picker-button.activated.sc-ion-picker-ios {
  color: white;
}

.card-background-page {
  ion-card {
    position: relative;
    text-align: left;
    border-radius: 8px !important;
  }

  .card-title {
    position: absolute;
    top: 26%;
    left: 5%;
    font-size: 7vw;
    width: 100%;
    font-weight: bold;
    color: #fff;
  }

  .card-subtitle {
    font-size: 3.5vw;
    position: absolute;
    top: 55%;
    left: 5%;
    width: 100%;
    color: #fff;
  }
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.alert-wrapper {
  border-radius: 0 !important;
}

.button-ios,
.button-md,
.button-solid {
  height: 2.25rem !important;
}

ion-button.modale__chiudi {
  --border-radius: 0% !important;
  height: 2.25rem !important;
  float: right;
  --color: white;
}

ion-title {
  overflow: visible;
  display: flex;
  width: 100%;
  justify-content: center;
}

.select-icon {
  width: 20px !important;
  height: 18px;
}

.controls__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls__container ion-button {
  --border-radius: 0;
  color: #041023;
  min-width: 90px;
  text-transform: uppercase;
}

.busy__umbrella {
  pointer-events: none;
}

.display__flex {
  display: flex;
}

.justify__center {
  justify-content: center;
}

.align__items__center {
  align-items: center;
}

.testo-titolo {
  // background-color: #00afff !important;
  margin: 0;
  padding: 6px;
  color: white !important;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.padding__right__12 {
  padding-right: 12px;
}

.text__white {
  color: white !important;
}

.text__bold {
  font-weight: bold;
}

.text__center {
  text-align: center;
}

.font__1_15__rem {
  font-size: 1.15rem !important;
}

.flex__direction__column {
  flex-direction: column;
}

.width__100 {
  width: 100%;
}

.logo__style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.padding__top__40 {
  padding-top: 40px;
}

.padding__top__16 {
  padding-top: 16px;
}

.height__2_25rem {
  height: 2.25rem !important;
}

.margin__0 {
  margin: 0;
}

.button__outline__white {
  --border-color: white;
  color: white;
  --background-activated: white;
}


.modaleInformativa {
  position: absolute;
  top: 30%;
  max-height: 250px;
  margin: 16px;

}


.margin__top__bottom__8 {
  margin: 8px 0;
}

.margin__top__2 {
  margin-top: 2px;
}

.margin__top__4 {
  margin-top: 4px;
}

.margin__top__40 {
  margin-top: 40px;
}

.text__red {
  color: red !important;
}

.text__white {
  color: white !important;
}

.text__green {
  color: green !important;
}

.text__right {
  text-align: right;
}

.text__bold {
  font-weight: bold;
}

.text__uppercase {
  text-transform: uppercase;
}

.padding__left__right__5 {
  padding: 0 5px;
}

.padding__left__right__10 {
  padding: 0 10px;
}

.padding__left__right__16 {
  padding: 0 16px;
}

.padding__left__right__8 {
  padding: 0 8px;
}

.padding__right__5 {
  padding-right: 5px;
}

.padding__right__16 {
  padding-right: 16px;
}

.padding__left__19 {
  padding-left: 19px;
}

.padding__right__12 {
  padding-right: 12px;
}

.padding__left__8 {
  padding-left: 8px;
}

.padding__left__16 {
  padding-left: 16px;
}

.padding__top__16 {
  padding-top: 16px;
}

.padding__bottom__16 {
  padding-bottom: 16px;
}

.padding__top__8 {
  padding-top: 8px;
}

.padding__top__0 {
  padding-top: 0px;
}

.padding__bottom__40 {
  padding-bottom: 40px !important;
}

.padding__bottom__80 {
  padding-bottom: 80px !important;
}

.padding__bottom__0 {
  padding-bottom: 0px !important;
}

.padding__0 {
  padding: 0;
}

.padding__16 {
  padding: 16px;
}

.padding__14 {
  padding: 14px;
}

.padding__8 {
  padding: 8px;
}

.padding__25 {
  padding: 25px;
}

.height__2_25rem {
  height: 2.25rem !important;
}

.height__100 {
  height: 100%;
}

.height__90 {
  height: 90%;
}

.height__95 {
  height: 95%;
}

.margin__16 {
  margin: 16px;
}

.margin__bottom__8 {
  margin-bottom: 8px;
}

.margin__bottom__16 {
  margin-bottom: 16px;
}

.margin__top__0 {
  margin-top: 0px;
}

.margin__top__16 {
  margin-top: 16px;
}

.margin__top__25 {
  margin-top: 25px;
}

.margin__bottom__0 {
  margin-bottom: 0px !important;
}

.margin__left__16 {
  margin-left: 16px;
}

.margin__left__5 {
  margin-left: 5px;
}

.margin__left__right__8 {
  margin: 0 8px;
}

.margin__0 {
  margin: 0;
}

.font__1_8__rem {
  font-size: 1.8rem;
}

.text__center {
  text-align: center;
}

.text__left {
  text-align: left;
}

.display__flex {
  display: flex;
}

.display__inline__block {
  display: inline-block;
}

.display__none {
  display: none;
}

.justify__center {
  justify-content: center;
}

.justify__space__evenly {
  justify-content: space-evenly;
}

.pointer__events__none {
  pointer-events: none;
}

.toolbar__allineatore {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
}

.margin__0 {
  margin: 0 auto;
}

.border__radius__30 {
  border-radius: 30px;
}

.border__radius__0 {
  border-radius: 0;
}

.border__0 {
  border: 0;
}

.width__auto {
  width: auto;
}

.position__absolute {
  position: absolute;
}

.position__relative {
  position: relative;
}

.style__foto__profilo {
  display: inline-block;
  border-radius: 200px;
  width: 200px;
  height: 200px;
}

.logo__style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.width__100 {
  width: 100%;
}

.width__50 {
  width: 50%;
}

.font__xsmall {
  font-size: x-small !important;
}

.welcome-page__testo {
  text-transform: uppercase;
}

.flag__selected {
  width: 25px;
  height: auto;
  /*  position: absolute;
      top: 17%;
      right: 17%; */
}

.button-outline {
  border: 2px solid;
}

.font__1_15__rem {
  font-size: 1.15rem !important;
}

.font__1_11__rem {
  font-size: 1.11rem !important;
}

.alert-button-inner {
  font-size: 1.15rem;
}

.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md {
  font-size: 1.15rem;
}

.drop__shadow__white__text {
  filter: drop-shadow(0 0 8px rgba(72, 83, 84, 0.9));
}

.min__width__150 {
  min-width: 150px;
}

.disabled__element {
  opacity: 0.35;
  pointer-events: none;
}

.flex__direction__row {
  flex-direction: row;
}

.flex__direction__column {
  flex-direction: column;
}

.popover-ombrelloni .popover-content,
.popover-bar-ristorante .popover-content {
  width: calc(100% - 10px);
}

/* input {
  font-size: 16px;
  height: 32px !important;
  line-height: 24px;
  letter-spacing: normal;
  color: rgb(72, 72, 72);
  border-style: solid;
  border-radius: 4px;
  z-index: 0;
  margin-bottom: 8px;
  display: block;
  width: 100% !important;
  background-color: rgb(255, 248, 246) !important;
  border-width: 1px;
}
 */
.margin__left__auto {
  margin-left: auto;
}

.margin__bottom__16 {
  margin-bottom: 16px;
}

.margin__right__auto {
  margin-right: auto;
}



.search-ombrellone {
  width: calc(100% - 10px);
}

.search-ombrellone .popover-content,
.popover-bar-ristorante .popover-content {
  width: calc(100% - 10px);
  border: 1px solid white;
}

.ios {

  .search-ombrellone,
  .popover-bar-ristorante,
  .webCamPopOver,
  .prenotaRistorantePopover {
    &::part(content) {
      border: 1px solid white;
      padding: 5px;
      padding-bottom: 30px;
      width: calc(100% - 40px) !important;
      max-width: 600px;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .prenotaRistorantePopover,
  .prenotaOmbrellonePopover {
    &::part(content) {
      width: calc(100% - 40px) !important;
      max-width: 600px;
      //  height: calc(60% - 10px) !important;
      //  max-height: 430px;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}

.md {

  .search-ombrellone,
  .popover-bar-ristorante,
  .webCamPopOver,
  .prenotaRistorantePopover {
    &::part(content) {
      width: calc(100% - 24px) !important;
      max-width: 600px;
    }
  }

  .prenotaRistorantePopover,
  .prenotaOmbrellonePopover {
    &::part(content) {
      width: calc(100% - 24px) !important;
      max-width: 600px;
      height: calc(60% - 24px) !important;
      max-height: 430px;
    }
  }
}

.testo-scelta-ombrellone {
  font-size: 18px;
  color: white !important;
  margin: 8px;
}